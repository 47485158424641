import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Popups, { ShowSuccess, ShowError } from './Modules/Popups';

import Account_Header from './Modules/Account_Header';
import Account_Navigation from './Modules/Account_Navigation';
import AccountHelpMessages from './AccountHelpMessages';

import loading_image from '../images/loading2.gif';
import empty_image from "../images/empty.png";
import image_logo from '../images/logo.png';


const AccountHelp = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [InvalidToken, SetInvalidToken] = React.useState(false);

    const [Id, SetId] = React.useState(0);
    const [Username, SetUsername] = React.useState("Username");
    const [Avatar, SetAvatar] = React.useState("Avatar");
    const [Balance, SetBalance] = React.useState(0);

    const [CurrentTicket, SetCurrentTicket] = React.useState(0);
    const [newMessage, SetNewMessage] = React.useState("");
    const [CurrentSort, SetCurrentSort] = React.useState("0");

    const [SubscriptionsArray, SetSubscriptionsArray] = React.useState([]);

    const [TicketsParsed, SetTicketsParsed] = React.useState(false);
    const [TicketsArray, SetTicketsArray] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }
    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }
    
    function SetUrl(paramName, newValue) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(paramName, newValue); // Устанавливаем или заменяем значение

        let newUrl = window.location.pathname; 
        if (searchParams.toString() !== "") {
            newUrl += "?" + searchParams.toString(); // Добавляем параметры, если есть
        }
        window.history.replaceState({}, '', newUrl);
    }
    function GetParameterUrl(paramName) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(paramName);
    }

    useEffect(()=> {
        if (cookies.balance != null) SetBalance(cookies.balance);
        
        axios.post('https://api.0xcheats.su/Account/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                SetId(json.id);
                SetUsername(json.username);
                SetSubscriptionsArray(json.subscriptions);

                if (json.avatar.length == 0)
                    SetAvatar("https://api.0xcheats.su/Images/Avatars/invalid.jpg");
                else
                    SetAvatar("https://api.0xcheats.su/Images/Avatars/" + json.avatar);

                SetBalance(json.balance);
                setCookie("balance", json.balance, { path: "/" });
            }
            else if (json.failed != null) {
                SetInvalidToken(true);
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });

        if (!TicketsParsed) {

            ParseTickets();

            setInterval(() => {
                console.log("Parsing tickets...");
                ParseTickets();
            }, 30000);

            // window.addEventListener('focus', function() {
            //     titleState = 0;
            //     clearInterval(intervalId);
            //     document.title = "0xCheats.net";
            // });

            let openedTicket = GetParameterUrl("ticket");
            if (openedTicket != null) OpenTicket(openedTicket, "", "");
        }

    }, [])
    if (InvalidToken) {
        removeCookie("token", { path: "/" });
        return <Navigate to="/auth" replace />;
    }

    function ParseTickets() {
        axios.post('https://api.0xcheats.su/Account/Help/Tickets', qs.stringify({ 'token': cookies.token }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                SetTicketsParsed(true);
                SetTicketsArray(json.tickets);
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }
    function Ticket(ticket) {

        //Открытые
        if (CurrentSort == "" && ticket.status != 1) {
            return (
                <>
                </>
            );
        }
        //Админ
        if (CurrentSort == " admin" && ticket.status != 2) {
            return (
                <>
                </>
            );
        }
        //Возврат
        if (CurrentSort == " refund" && ticket.status != 3) {
            return (
                <>
                </>
            );
        }
        //Закрытые
        if (CurrentSort == " close" && ticket.status != 0) {
            return (
                <>
                </>
            );
        }

        return (
            <div id={"ticket" + ticket.id} key={ticket.id} className={`${ticket.status == 1 ? "ticket" : ticket.status == 0 ? "ticket close" : ticket.status == 2 ? "ticket admin" : ticket.status == 3 ? "ticket refund" : "ticket"} ${CurrentTicket == ticket.id ? "active" : ""} `} onClick={() => OpenTicket(ticket.id, ticket.name, ticket.type)}>
                <p className="status-view">{ ticket.status == 0 ? t('help_ticket_parse_closed') : ticket.status == 1 ? t('help_ticket_parse_open') : ticket.status == 2 ? t('help_ticket_parse_admin') : ticket.status == 3 ? t('help_ticket_parse_refund') : t('help_ticket_parse_closed') }</p>
                <div>
                    <p className="name">{ticket.name}</p>
                    <span>{ticket.type}</span>
                </div>
                {/*<p className="last-reply">{ticket.last_answer_date}<span><i className="icon message"></i></span></p>*/}
            </div>
        );
    }
    function Tickets() {
        if (TicketsParsed) {
            return TicketsArray.map((ticket) =>
                <Ticket key={ticket.id} id={ticket.id} status={ticket.status} name={ticket.name} type={ticket.type} last_answer_date={ticket.last_answer_date} />
            );
        }
        else {
            return (
            <>
                <div className="loading">
                    <div><br/><br/><br/><br/></div>
                </div>
                <br/><br/>

                <div className="loading">
                    <div><br/><br/><br/><br/></div>
                </div>
                <br/><br/>

                <div className="loading">
                    <div><br/><br/><br/><br/></div>
                </div>
                <br/><br/>
            </>
            );
        }
    }

    var currentTicket = 0;
    function OpenTicket(ticket, title, description) {
        currentTicket = ticket;
        SetCurrentTicket(ticket);
        SetNewMessage("");

        SetUrl("ticket", ticket);

        document.getElementById("create_new").style.display = "none";
        document.getElementById("ticket_body").style.display = "block";

        document.getElementById("ticket_title").innerHTML = title;
        document.getElementById("ticket_description").innerHTML = description;
        document.getElementById("ticket_id").innerHTML = "#" + ticket;

        //Тикет
        let foundTicket = document.getElementById('ticket' + ticket);
        if (foundTicket != null) {
            //Если тикет закрыт
            if (foundTicket.classList.contains('close'))
            {
                document.getElementById("ticket_send_message").style.display = "none";
                document.getElementById("ticket_send_message_closed").style.display = "flex";

                document.getElementById("ticket_close_text").style.display = "none";
                document.getElementById("ticket_close_button").style.display = "none";
            }
            else {
                document.getElementById("ticket_send_message").style.display = "flex";
                document.getElementById("ticket_send_message_closed").style.display = "none";

                document.getElementById("ticket_close_text").style.display = "block";
                document.getElementById("ticket_close_button").style.display = "flex";
            }
        }
        else {
            //Тикет не найден (возможно только создан)
            document.getElementById("ticket_send_message").style.display = "flex";
            document.getElementById("ticket_send_message_closed").style.display = "none";

            document.getElementById("ticket_close_text").style.display = "block";
            document.getElementById("ticket_close_button").style.display = "flex";
        }
    }
    function LeaveTicket() {
        currentTicket = 0;
        SetCurrentTicket(0);

        document.getElementById("create_new").style.display = "block";
        document.getElementById("ticket_body").style.display = "none";
    }
    function CloseTicket() {
        axios.post('https://api.0xcheats.su/Account/Help/Ticket/Close', qs.stringify({ 'token': cookies.token, 'ticket': CurrentTicket }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                LeaveTicket();
                ParseTickets();
            }
            else if (json.failed != null)
            {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            document.getElementById("_help").className = "nav-link active";
        });
    }

    function Sort(status) {
        let current = document.getElementById('sort' + CurrentSort);
        if (current != null) current.classList.remove("active");

        if (CurrentSort == status) {
            SetCurrentSort("0");
        }
        else {
            document.getElementById('sort' + status).classList.add("active");
            SetCurrentSort(status);
        }

        //if (ticket != null) ticket.classList.add('active');
    }

    function RestoreSortAndTicket() {

        Sort(CurrentSort);

        //Ticket
        let ticket = document.getElementById('ticket' + currentTicket);
        if (ticket != null) ticket.classList.add('active');

        //Sort
        let current = document.getElementById('sort' + CurrentSort);
        if (current != null) current.classList.add("active");
    }


    function NewTicketOptions() {
        if (SubscriptionsArray.length > 0) {
            return SubscriptionsArray.map((subscription) =>
                <option key={subscription.identifier} value={subscription.identifier}>{subscription.name}</option>
            );
        }
    }
    function CreateTicket() {

        let select = document.getElementById('create_new_select').value;
        let title = document.getElementById('create_new_title').value;
        let body = document.getElementById('create_new_body').value;

        //Проверяем поля
        if (select.length < 1) {
            ShowError(t("help_error_1"));
            return;
        }
        if (title.length < 3) {
            ShowError(t("help_error_2"));
            return;
        }
        if (title.length > 24) {
            ShowError(t("help_error_3"));
            return;
        }
        if (body.length < 3) {
            ShowError(t("help_error_4"));
            return;
        }

        document.getElementById('create_ticket_button').style.display = "none";

        axios.post('https://api.0xcheats.su/Account/Help/Tickets/New', qs.stringify({ 'token': cookies.token, 'select': select, 'title': title, 'body': body }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);

            if (json.success == "true") {
                //Сбрасываем поля ввода
                document.getElementById('create_new_select').value = "0";
                document.getElementById('create_new_title').value = "";
                document.getElementById('create_new_body').value = "";

                //Открываем тикет и обновляем список
                ParseTickets();
                OpenTicket(json.ticket, json.title, json.description);
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            document.getElementById('create_ticket_button').style.display = "flex";
        });
    }
    function CreateNewMessage() {
        let message = document.getElementById('ticket_new_message').value;

        message = message.trim();

        //Проверяем поля
        if (message.length < 1) {
            ShowError(t("help_error_5"));
            return;
        }

        //Скрываем кнопку отправки сообщения
        document.getElementById('create_message_button').style.display = "none";
        document.getElementById('create_message_button2').style.display = "flex";

        axios.post('https://api.0xcheats.su/Account/Help/Ticket/NewMessage', qs.stringify({ 'token': cookies.token, 'ticket': CurrentTicket, 'message': message }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);

            if (json.success == "true") {
                //Обновляем список сообщений
                SetNewMessage(message);
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }

    return (
    <>
        <Popups/>
        <div className="mobile-menu-overlay">
            <div className="input activate-key">
                <p className="btn black">Активировать</p>
                <input type="text" placeholder="Введите код активации"/>
            </div>
            {/*<div className="payment-block">
                <div className="balance">
                    <a href="" className="btn black go-up-balance">Пополнить</a>
                    <span>50 000 <b>руб</b></span>
                </div>
                <div className="pay"><i className="icon payment"></i></div>
            </div>*/}
        </div>

        <section id="tickets" className="profile-page">

            <Account_Navigation active={"help"}/>

            <div className="container">
                <div className="content">
                    <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                    <div className="hl-reson"></div>
                    <div className="pre-title no-m fadeout"><i className="icon support2"></i>{t('help_title')}</div>

                    <div className="split">
                        <div id="tickets_list" className="tickets">
                            <div className="sort-block fadeout">
                                <div className="sort-wrapper" onClick={() => Sort("")}><p id="sort" className="sort no-reply">{t('help_ticket_open')}</p></div>
                                <div className="sort-wrapper" onClick={() => Sort(" admin")}><p id="sort admin" className="sort admin">{t('help_ticket_admin')}</p></div>
                                <div className="sort-wrapper" onClick={() => Sort(" refund")}><p id="sort refund" className="sort refund">{t('help_ticket_refund')}</p></div>
                                <div className="sort-wrapper" onClick={() => Sort(" close")}><p id="sort close" className="sort close">{t('help_ticket_closed')}</p></div>
                            </div>
                            <Tickets/>
                        </div>

                        <div className="vr-repos"></div>

                        <div id="create_new" className="create-ticket-block fadeout">
                            <div className="warning"><i className="icon question" style={{paddingRight:"25px"}}></i><font color="white">{t('help_faq')}</font></div>
                            <div className="form-create">
                                <div className="type">
                                    <p>{t('help_ticket_type_title')}</p>
                                    <select id="create_new_select" type="text" placeholder="">
                                        <option value="0">{t('help_ticket_type_question')}</option>
                                        <option value="1">{t('help_ticket_type_idea')}</option>
                                        <option value="2">{t('help_ticket_type_partner')}</option>
                                        <NewTicketOptions/>
                                    </select>
                                </div>
                                <input id="create_new_title" type="text" placeholder={t('help_ticket_name')} maxLength="24"/>
                                <textarea id="create_new_body" placeholder={t('help_ticket_body')}></textarea>
                                <a id="create_ticket_button" className="btn black clickable fadeout" onClick={() => CreateTicket()}><i className="icon plus"></i>{t('help_ticket_create')}</a>
                            </div>
                        </div>

                        <div id="ticket_body" className="ticket-messanger fadeout" style={{display: "none"}}>
                            <div className="header">
                                <div className="info">
                                    <p className="theme"><i className="icon ticket"></i></p>
                                    <p className="more">
                                        <span id="ticket_title" className="id">Ticket #0</span>
                                        <span id="ticket_description">Тестовый</span>
                                        <span id="ticket_id">#0</span>
                                    </p>
                                </div>
                                <div className="actions">
                                    <span id="ticket_close_text">{t('help_ticket_close')}</span>
                                    <p id="ticket_close_button" onClick={() => CloseTicket()}><i className="icon ticket_close"></i></p>
                                    <p><i className="icon ticket_leave" onClick={LeaveTicket}></i></p>
                                </div>
                            </div>

                            <div id="ticket_messages" className="chat-window fadeout">
                                <AccountHelpMessages ticket={CurrentTicket}  message={newMessage} />
                            </div>

                            <div id="ticket_send_message" className="send-msg">
                                <textarea id="ticket_new_message" className="accept-icon-input" placeholder={t('help_ticket_enter_message')}></textarea>
                                {/*<div className="" contenteditable="true" placeholder="Введите сообщение"></div>*/}
                                {/*<a href="javascript:void(0)" className="emoji-button" title="Emojis"><i className="icon smile"></i></a>*/}
                                {/*<p className="files"><i className="icon attach"></i></p>*/}
                                <a id="create_message_button" className="btn black send" onClick={() => CreateNewMessage()}><i className="icon send"></i></a>
                                <a id="create_message_button2" className="btn black send" style={{display: "none"}}><img src={loading_image} width='168'/></a>
                            </div>
                            <div id="ticket_send_message_closed" className="send-msg-closed" style={{display: "none"}}>
                                <p>{t('help_ticket_already_closed')}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </>
    );
};
export default AccountHelp;